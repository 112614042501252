import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import services from '../services/servicesTeso';
import { onLogin, onLogout, setPais, setPaisDataForm, upSertPais, DataSelectPais, setEstadoCargandoTrue, setEstadoCargandoFalse } from '../store';
import axios from 'axios';
import Swal from 'sweetalert2';



export const usePaisEmpresaStore = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const listPaisEmpresa = async (sis_id) => {

        //Se setea en true el spinner para que se muestre en la vista
        dispatch( setEstadoCargandoTrue());

        const token = localStorage.getItem('token');
        
        checkAuthToken();
            try {

                const headerAxiosListar = {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }

                const { data } = await axios.post(
                    services.API.Auth.ApiAutenticacion_Listar_Pais_Empresa,
                    {
                        sis_id
                    },
                    { headers: headerAxiosListar }
                );

                

                if (data.respuesta_tipo === "success"){
                    
                    //Se setea en False para que se deje de mostrar el spinner
                    dispatch( setEstadoCargandoFalse());
                    
                    return data.data;
                    
                }
                else if(data.respuesta_tipo === "warning"){

                    //Se setea en False para que se deje de mostrar el spinner
                    dispatch( setEstadoCargandoFalse());

                    Swal.fire(
                        'Verifica tus datos',
                        data.respuesta_detalle,
                      )

                      
                }
                else if (data.respuesta_tipo === "error") {

                     //Se setea en False para que se deje de mostrar el spinner
                     dispatch( setEstadoCargandoFalse());

                    Swal.fire({
                        icon: 'error',
                        title: 'Error en BD',
                        text: data.respuesta_detalle,
                        footer: ''
                        }
                    );

                   
                }
            } catch (error) {
                
                //Se setea en False para que se deje de mostrar el spinner
                dispatch( setEstadoCargandoFalse());
                navigate('/pages/principal');
                
            }
       
    }

    const checkAuthToken = async() => {
        
        const token2 = sessionStorage.getItem('token');
        const token = localStorage.getItem('token');
        if ( !token && !token2 ) return dispatch( onLogout() );

        try {   //Se recuperan los datos sesionStorage porque se pierden al recargar, de esta manera validamos que el token siga vigente y no cierre sesión al refrescar.
            //const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Validacion, { token });
            //const { data } = await calendarApi.get('auth/renew');
            const nombre = sessionStorage.getItem('Name');
            const usuario = sessionStorage.getItem('payload');
            
            localStorage.setItem('Name', nombre);
            localStorage.setItem('payload', usuario);
            localStorage.setItem('token', token2 );
            localStorage.setItem('token-init-date', new Date().getTime() );
            //sessionStorage.clear();
            //dispatch( onLogout() );
            dispatch( onLogin({ name: nombre, uid: usuario }) );
        } catch (error) {
            localStorage.clear();
            dispatch( onLogout() );
        }
    }
    
    return {
        listPaisEmpresa
    }

}