import { createSlice } from '@reduxjs/toolkit';

export const sistemaSlice = createSlice({
    name: 'sistemaRedux',
    initialState: {
        sistemas: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        upDataSistemas: (state, { payload  } ) => {
            state.sistemas = payload.sistemas;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    upDataSistemas,
} = sistemaSlice.actions;