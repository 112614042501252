import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Main from './layouts/Main';
import Principal from './views/Principal/Principal';
import NotFound from "./views/NotFound/NotFound";

// import routes
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

import { useEffect } from 'react';
import { useAuthStore } from './hooks';
//import  Signin  from './views/Signin/Signin'; 
import Signin from "./views/Signin/Signin";

import Swal from 'sweetalert2';


// import css
import "./styles/assets/css/remixicon.css";

// import scss
import "./styles/scss/style.scss";
import "./styles/scss/pages/_spinnerLogin.scss";

import SpinnerCarga from "./sharedComponents/SpinnerCarga";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {

  //const rutaServidor = "/";
  //const rutaServidor = "/autenticacion";
  const { status, checkAuthToken, abrirCargar } = useAuthStore();

  useEffect(() => {
      checkAuthToken();
  }, [])

  if ( status === 'checking' ) {
    
      return (

           <SpinnerCarga title="Iniciando sesión..." />

      )
  }
  return (
    <React.Fragment>
    <BrowserRouter>
      <Routes>
        {
           ( status === 'not-authenticated')
              ? (
                  <>
                  <Route path="/" element={<Signin />}></Route>
                  <Route path="/*" element={<Signin />} />
                  </>
                )
                : (
                  <>
                   <Route path={"/"} element={<Main />}>
                    {protectedRoutes.map((route, index) => {
                      return (
                        <Route
                          path={route.path}
                          element={route.element}
                          key={index}
                        />
                      )
                    })}
                  </Route>
                  </>
                )
        }
         <Route path="*" element={<NotFound />} /> 
      </Routes>
    </BrowserRouter>
  </React.Fragment>
  )
}