import React from "react";
import "../styles/scss/panels/_footer.scss";
import logo from "../styles/assets/img/logo3.png";
import BanderaCR from "../styles/assets/img/Bandera-CR.png";
import BanderaNIC from "../styles/assets/img/Bandera-NIC.png";
import BanderaPAN from "../styles/assets/img/Bandera-PAN.png";
import BanderaSLV from "../styles/assets/img/Bandera-SLV.png";
import IconoFacebook from "../styles/assets/img/Facebook-icon.png";
import IconoInstagram from "../styles/assets/img/Instagram-icon.png";
import IconoYoutube from "../styles/assets/img/Youtube-icon.png";
import IconoLinkedin from "../styles/assets/img/Linkedin-icon.png";

export default function Footer() {
  return (
    <div className="footer align-items-stretch">
      <div className="footer-content">
        <div className="rectangle">
          <div className="column">
            <img src={logo} alt="Imagen 1" className="logo" />
          </div>
          <div className="column">
            <p className="footer-text">
              Somos una financiera con presencia en 4 países
              y que aspira a seguir brindándole soluciones a más países de la región
            </p>
          </div>
          <div className="column">
            <img src={BanderaCR} alt="Imagen 2" className="circle-image" />
            <img src={BanderaPAN} alt="Imagen 3" className="circle-image" />
            <img src={BanderaNIC} alt="Imagen 3" className="circle-image" />
            <img src={BanderaSLV} alt="Imagen 3" className="circle-image" />
          </div>
        </div>
      </div>
      <div className="footerAbajo">
        <div className="column">
          <label>Conozca la Intranet</label>
        </div>
        <div className="column">
          <label>2023 Instacredit</label>
        </div>
        <div className="column">
          <img src={IconoFacebook} alt="Imagen 4" className="circle-image" />
          <img src={IconoInstagram} alt="Imagen 5" className="circle-image" />
          <img src={IconoYoutube} alt="Imagen 6" className="circle-image" />
          <img src={IconoLinkedin} alt="Imagen 7" className="circle-image" />
        </div>
      </div>
    </div>
  );
}
