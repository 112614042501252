import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

export default function Main() {

  
  const navigate = useNavigate();

  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

    //Función para que al cargar la pagina principal, redirige al Home y no se quede el outlet en blanco.
    const redirigirHome = () => {

      navigate('/pages/principal');
  
    }
  
    useEffect(
      () => {
        redirigirHome();
      },
      []
    );
  

  return (
    <React.Fragment>
      <Sidebar />
      <Header />
      <Outlet />
      <Footer />
    </React.Fragment>
  )
}