import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../../sharedComponents/CardComponent/CardComponent';
import { Card } from "react-bootstrap";
import { usePaisEmpresaStore } from '../../hooks/usePaisEmpresaStore';
import logo from "../../styles/assets/img/logo.png";
import Swal from 'sweetalert2';


//Spinner
import { useAuthStore } from "../../hooks";
import SpinnerCarga from "../../sharedComponents/SpinnerCarga";

const Pais_Empresa = () => {

    const navigate = useNavigate();

    const { estadoCargando } = useAuthStore();

    let sis_id = localStorage.getItem("id_sistema");

    const { listPaisEmpresa } = usePaisEmpresaStore();
    const [dataPaisesEmpresas, setDataPaisesEmpresas] = useState([]);


      const redireccionSistemaPaisEmpresa = ( sistema_id ) => {

        const token = localStorage.getItem("token");
        const user = localStorage.getItem("payload");
        const data = localStorage.getItem("link_sistema");

        
        const url = `${data}?start=${encodeURIComponent(token)}&user=${encodeURIComponent(user)}&system=${encodeURIComponent(sistema_id)}`;


        window.open(url, "_blank");
        navigate('/pages/principal');

      };

      const cargaDatos = async () => {

        try {

        const valor = await listPaisEmpresa(sis_id);

        
        setDataPaisesEmpresas( valor );
  
            
        } catch (error) {

            Swal.fire(
                'Error en BD.',
                error,
              )
            
        }
    };

  useEffect( () => { cargaDatos(); } , [] );

  //Validación para mostrar el spinner
  if ( estadoCargando === true ) {
    return (
      
    <SpinnerCarga title="Cargando lista de Paises y Empresas..." />
  
  )
  }



  return (
   

    <div className="main main-app p-3 p-lg-4">

        <div className="d-md-flex align-items-center justify-content-between mb-1">
          <div>
            <img
              src={logo}
              alt="Logo de Instacredit"
              style={{ margin: "auto", width: "200px", height: "auto" }}
            />
          </div>
          {/* <Button
            variant="primary"
            className="button-support"
            as={Link}
            style={{
              fontSize: "1rem",
              backgroundColor: "white",
              borderColor: "white",
              borderRadius: "40px",
              height: "30px",
              color: "darkgreen",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Soporte
          </Button> */}
        </div>
      
      <Card.Body>

        <h5 className='mb-3'>Seleccione una de las siguientes opciones de País y Empresa:</h5>    
        <div className="container d-flex justify-content-center align-items-center h-100 mb-2">
            <div className="row justify-content-center align-items-center mb-2">


                { dataPaisesEmpresas.map( ( { pae_id, pai_nombre, emp_nombre  } ) => 
                    (

                    
            
                        <div className="col-md-3" key={pae_id}>           

                            <CardComponent
                             imagenPais={ pai_nombre}
                             NombreEmpresa={ emp_nombre }
                             id_Pais_Sistema={ pae_id }
                             funcion={ redireccionSistemaPaisEmpresa }
                            />       

                            </div>
    
                    
                    )
                )
                }
            </div>
        </div>

          </Card.Body>

    </div>

  )
}

export default Pais_Empresa
