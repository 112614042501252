import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        usuarios: [
            // tempEvent
        ],
        tipo_usuarios: [],
        roles: [],
        usuarios_roles: [],
        rolesUsuario: [],
        redireccion: [],
        getDataForm: [],
    },
    reducers: {
        setUsers: (state, { payload } ) => {
            state.usuarios = payload;
        },
        setDataUpsert: (state, { payload} ) => {
            state.tipo_usuarios = payload.tipo_usuarios.tipo_usuarios;
        },
        upSertUsert: (state, {payload}) => {
            state.usuarios = payload.respuesta.usuarios;
            state.redireccion = payload.respuesta.respuesta_tipo;
        },
        setUserDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setSelectRoles: (state, { payload  } ) => {
            state.roles = payload.roles;
        },
        setSelectRolesAgregados: (state, { payload  } ) => {
            state.usuarios_roles = payload.usuarios_roles;
        },
        upRolesUsuario: (state, { payload  } ) => {
            state.rolesUsuario = payload.rolesUsuario;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setUsers,
    setDataUpsert,
    upSertUsert,
    setUserDataForm,
    setSelectRoles,
    upRolesUsuario,
    setSelectRolesAgregados,
} = userSlice.actions;