import React from "react";

//Page Standard
import Standard from "../views/Standard/Standard";

//Mantenimientos Generales
import Principal from "../views/Principal/Principal";
import Pais_Empresa from "../views/Pais_Empresa/Pais_Empresa";



const protectedRoutes = [
  { path: "pages/standard", element: <Standard /> },
  { path: "pages/principal", element: <Principal /> },
  { path: "pages/pais_empresa", element: <Pais_Empresa /> },

]

export default protectedRoutes;