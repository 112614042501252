import React from "react";
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { PersonFill, LockFill } from 'react-bootstrap-icons';
import logo from "../../styles/assets/img/logo.png";
import userIcon  from "../../styles/assets/img/User-icon.png";
import passwordIcon  from "../../styles/assets/img/Password-Icon.png";
import { Link } from "react-router-dom";
import { useAuthStore, useForm } from '../../hooks';
import Swal from 'sweetalert2';
import imgLogin from "../../styles/assets/img/Login.png";
import "../../styles/Login.css";

const loginFormFields = {
  loginUser: '',
  loginPassword: '',
}

export default function Signin() {
  const { startLogin, errorMessage } = useAuthStore();
  const { loginUser, loginPassword, onInputChange: onLoginInputChange } = useForm(loginFormFields);

  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false); // State for modal visibility
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const loginSubmit = (event) => {
    event.preventDefault();
    startLogin({ usuario: loginUser, clave: loginPassword, sistema: "Portal", active_directory: true });
  }

  const handlePasswordResetClick = () => {
    setShowPasswordResetModal(true);
  }

  const handlePasswordResetModalClose = () => {
    setShowPasswordResetModal(false);
    setNewPassword('');
    setConfirmPassword('');
  }

  const handlePasswordReset = () => {
    // Implement your password reset logic here
    // You can validate newPassword and confirmPassword and perform the reset action
    // Display a success message and close the modal
    setShowPasswordResetModal(false);
    setNewPassword('');
    setConfirmPassword('');
  }


  return (
    <div className="page-sign d-block py-0" style={{ height: '100vh', overflow: 'hidden' }}>
      <Row className="g-0">
        <Col lg={5} className="col-wrapper d-flex align-items-center justify-content-center" style={{  height: '100%', overflow: 'auto' }}>
          <Card className="card-sign d-flex flex-column shadow-lg p-3 mb-5 bg-body rounded">
            <Card.Header>
              <Link to="/" className="header-logo mb-5 ml-2" style={{ display: 'flex', justifyContent: 'center'}}>
                <img src={logo} alt="Logo de Instacredit" style={{ margin: 'auto', width: '350px', height: '90px' }} />
              </Link>

              <Card.Title style={{ color: 'green', textAlign: 'center' }}>Bienvenido al portal de plataformas tecnológicas</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={loginSubmit}>
                <div className="mb-4">
                  <div className="input-icon">
                    <Form.Control type="text" style={{ borderRadius: '40px', height: '65px', width: '300px', borderColor: '#f2f2f2', paddingLeft: '60px', backgroundImage: `url(${userIcon})`, backgroundPosition: '20px center', textAlign: 'center',  lineHeight: '65px', fontSize: '22px', backgroundRepeat: 'no-repeat' }} name="loginUser" value={loginUser} onChange={onLoginInputChange} />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="input-icon">
                    <Form.Control type="password"  style={{ borderRadius: '40px',height: '65px', width: '300px', borderColor: '#f2f2f2', paddingLeft: '60px', backgroundImage: `url(${passwordIcon})`, backgroundPosition: '20px center',textAlign: 'center',  lineHeight: '65px', fontSize: '22px', backgroundRepeat: 'no-repeat' }} name="loginPassword" value={loginPassword} onChange={onLoginInputChange} />
                  </div>
                </div>
                
                <Button type="submit" variant="primary" className="btn-sign" style={{ fontSize: '20px', backgroundColor: '#0A1B61', borderColor: '#0A1B61', borderRadius: '40px', height: '65px', width: '300px' }}>Iniciar Sesión</Button>
                
                <div className="button-labels-container" style={{ marginTop: '30px', textAlign: 'end', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '300px', margin: '0 auto' }}>
                  <br></br>
                  {/* <Link to="#" className="forgot-password-link" onClick={handlePasswordResetClick}>¿Olvidó su contraseña?</Link> */}
                  <label style={{ textAlign: 'center' }}>Las Cookies deben de estar habilitadas en su navegador</label>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer className="text-center custom-footer">
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={7} className="d-none d-lg-block">
          <img src={imgLogin} className="auth-img " alt="" name="imagenLogin" style={{ width: '100%', height: '95%' }}/>
        </Col>
      </Row>

      <Modal show={showPasswordResetModal} onHide={handlePasswordResetModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Restablecer Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newPassword">
              <Form.Label>Nueva Contraseña</Form.Label>
              <Form.Control type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="confirmPassword">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handlePasswordResetModalClose}>Cancelar</Button>
          <Button variant="primary" onClick={handlePasswordReset}>Restablecer</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
