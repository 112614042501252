import { createSlice } from '@reduxjs/toolkit';

export const paisSlice = createSlice({
    name: 'pais',
    initialState: {
        pais: [],
        getDataForm: [],
    },
    reducers: {
        setPais: (state, { payload } ) => {
            state.pais = payload;
        },
        upSertPais: (state, {payload}) => {
            state.pais = payload.respuesta.pais;
        },
        setPaisDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setPais,
    upSertPais,
    setPaisDataForm
} = paisSlice.actions;