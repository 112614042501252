import { useDispatch, useSelector } from 'react-redux';
import services from '../services/servicesTeso';
import { clearErrorMessage, onChecking, onLogin, onLogout,upDataSistemas,setEstadoCargandoTrue, setEstadoCargandoFalse } from '../store';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


export const useSistema = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const DataSelectSistema = async () => {
        dispatch( setEstadoCargandoTrue());

    
        const token = localStorage.getItem('token');
        const USER = localStorage.getItem('payload');
        checkAuthToken();
    
        if (token && USER) {
            try {
                const headerAxios = {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
    
                const url = services.API.Sistema.ApiAutenticacion_Sistema_X_Usuario + '?USER=' + USER;
    
                const { data } = await axios.post(url);
    
                
    
                if (data.respuesta_tipo === "success") {
                    if (data.sistemas.length === 0) {
                        dispatch( setEstadoCargandoFalse());

                        Swal.fire({
                            icon: 'info',
                            title: 'Información',
                            text: 'No se encontraron sistemas disponibles para su usuario.',
                            footer: ''
                        });
                    } else {
                        dispatch( setEstadoCargandoFalse());
                        dispatch(upDataSistemas({ sistemas: data.sistemas }));
                    }
                }
            } catch (error) {
                dispatch( setEstadoCargandoFalse());
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudieron cargar los sistemas',
                    footer: ''
                });
            }
        }
        dispatch( setEstadoCargandoFalse());

    };
    

    




    const checkAuthToken = async() => {
        
        const token2 = sessionStorage.getItem('token');
        const token = localStorage.getItem('token');
        if ( !token && !token2 ) return dispatch( onLogout() );

        try {   //Se recuperan los datos sesionStorage porque se pierden al recargar, de esta manera validamos que el token siga vigente y no cierre sesión al refrescar.
            //const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Validacion, { token });
            //const { data } = await calendarApi.get('auth/renew');
            const nombre = sessionStorage.getItem('Name');
            const usuario = sessionStorage.getItem('payload');
            
            localStorage.setItem('Name', nombre);
            localStorage.setItem('payload', usuario);
            localStorage.setItem('token', token2 );
            localStorage.setItem('token-init-date', new Date().getTime() );
            //sessionStorage.clear();
            //dispatch( onLogout() );
            dispatch( onLogin({ name: nombre, uid: usuario }) );
        } catch (error) {
            localStorage.clear();
            dispatch( onLogout() );
        }
    }

      const abrirCargar = async() => {   
        Swal.fire({
            title: 'Cargando!',
            html: 'Un momento por favor.',
            timer: 1000,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading() 
            },
          })
    }

    return {
        //* Propiedades
        //errorMessage,
        //status, 
        //user, 

        //* Métodos

        DataSelectSistema,


        //checkAuthToken,
        //startLogin,
        //startLogout,
        //startRegister,
    }

}
