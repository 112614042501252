import { useDispatch, useSelector } from 'react-redux';
import { calendarApi } from '../api';
import services from '../services/servicesTeso';
//import servicesIDI from '../../lizst/servicesIDI.js'
import { clearErrorMessage, onChecking, onLogin, onLogout } from '../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


export const useAuthStore = () => {

    const { status, user, errorMessage, estadoCargando } = useSelector( state => state.auth );
    const dispatch = useDispatch();


    const startLogin = async({ usuario, clave, sistema, active_directory }) => {

        if(usuario.includes('@instacredit.com')){
            Swal.fire(
                'Atención',
                'El correo de empresa no es válido como usuario, digite la parte que se encuentra antes del @',
                'info'
              )
        }else{
            limpiezaDatos()
                dispatch( onChecking() );
                try {
                    const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Autorizacion, { usuario, clave, sistema, active_directory });

                   
                    
                    if(data.respuesta_tipo === "success"){
                        
                        //LocalStorage
                        localStorage.setItem('token', data.token );
                        localStorage.setItem('token-init-date', new Date().getTime() );
                        localStorage.setItem('Name', data.data.usu_nombre);
                        localStorage.setItem('payload', data.data.usu_usuario);
                        localStorage.setItem('sistema_nombre', data.data.sistema_nombre);
                        
                        //SesionStorage
                        sessionStorage.setItem('token', data.token );
                        sessionStorage.setItem('token-init-date', new Date().getTime() );
                        sessionStorage.setItem('Name', data.data.usu_nombre);
                        sessionStorage.setItem('payload', data.data.usu_usuario);
                        sessionStorage.setItem('sistema_nombre', data.data.sistema_nombre);
                        dispatch( onLogin({ name: data.data.usu_nombre, uid: data.data.usu_usuario }) );
        
                    } else if(data.respuesta_tipo === "warning"){
                       
                        dispatch( onLogout('Credenciales incorrectas') );
                        
    
                        Swal.fire(
                            "No se pudo iniciar sesión!",
                            "Por favor verifique sus datos o contacte a un administrador",
                            "info"
                          );
                        
    
                    } else if (data.respuesta_tipo === "error") {
                    
                        dispatch( onLogout('Credenciales incorrectas') );
    
                        Swal.fire(
                            "No se pudo iniciar sesión",
                            "Por favor verifique sus datos o contacte a un administrador",
                            "info"
                          );
    
                        
    
                    }
                } catch (error) {
                    dispatch( onLogout('Credenciales incorrectas') );
        
                    setTimeout(() => {
                        dispatch( clearErrorMessage() );
                    }, 10);

                    Swal.fire(
                        "No se pudo iniciar sesión",
                        "Por favor verifique sus datos o contacte a un administrador",
                        "info"
                      );
                }
        }
        
    }

    const startRegister = async({ usuario, password, name }) => {
        dispatch( onChecking() );
        try {
            const { data } = await calendarApi.post('/auth/new',{ usuario, password, name });
            localStorage.setItem('token', data.token );
            localStorage.setItem('token-init-date', new Date().getTime() );
            dispatch( onLogin({ name: data.name, uid: data.uid }) );
            
        } catch (error) {
            dispatch( onLogout( error.response.data?.msg || '--' ) );
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 10);
        }
    }


    const checkAuthToken = async() => {
        
        const token2 = sessionStorage.getItem('token');
        const token = localStorage.getItem('token');
        if ( !token && !token2 ) return dispatch( onLogout() );

        try {   //Se recuperan los datos sesionStorage porque se pierden al recargar, de esta manera validamos que el token siga vigente y no cierre sesión al refrescar.
            //const { data } = await axios.post(services.API.Auth.ApiAutenticacion_Validacion, { token });
            //const { data } = await calendarApi.get('auth/renew');
            const nombre = sessionStorage.getItem('Name');
            const usuario = sessionStorage.getItem('payload');
            const sistema = sessionStorage.getItem('sistema_nombre');
            if(!nombre){
                return dispatch( onLogout() );
            }
            
            localStorage.setItem('Name', nombre);
            localStorage.setItem('payload', usuario);
            localStorage.setItem('token', token2 );
            localStorage.setItem('token-init-date', new Date().getTime() );
            localStorage.setItem('sistema_nombre', sistema );
            //sessionStorage.clear();
            //dispatch( onLogout() );
            dispatch( onLogin({ name: nombre, uid: usuario }) );
        } catch (error) {
            localStorage.clear();
            dispatch( onLogout() );
        }
    }

    const startLogout = () => {
        limpiezaDatos();
        dispatch(onLogout());
    }

    const abrirCargar = async() => {   
        Swal.fire({
            title: 'Cargando!',
            html: 'Un momento por favor.',
            timer: 1000,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading() 
            },
          })
    }

    const limpiezaDatos = () => {


        //Limpieza local y de sesion
        localStorage.clear();
        sessionStorage.clear();

        //Limpieza de Redux. Por ejemplo:
        //dispatch( setValoresMenu( [''] )) ;

    }



    return {
        //* Propiedades
        errorMessage,
        status, 
        user,
        estadoCargando, 

        //* Métodos
        checkAuthToken,
        startLogin,
        startLogout,
        startRegister,
        abrirCargar
    }

}