import React, { useEffect,useState  } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../styles/assets/img/img1.jpg";
import { useAuthStore } from '../hooks/useAuthStore'
import CR from "../styles/assets/img/Bandera-CR.png";
import NIC from "../styles/assets/img/Bandera-NIC.png";
import PAN from "../styles/assets/img/Bandera-PAN.png";
import SLV from "../styles/assets/img/Bandera-SLV.png";


let nombreSistema = sessionStorage.getItem("sistema_nombre");

export default function Header({ onSkin }) {

  const imagenes = [
    {src: CR},
    {src: NIC},
    {src: PAN},
    {src: SLV},

  ]

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = () => {
    document.body.classList.toggle("sidebar-show");
    document.body.classList.toggle("sidebar-hide");
  }

  useEffect(() => {
    document.body.classList.add("sidebar-hide");
  }, []);

  const { startLogout, user } = useAuthStore();

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  }

  return (
    <div className="header-main px-3 px-lg-4">
      {/*<Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>*/}

      <div className="me-auto header-system-name">
        Portal de Plataformas Tecnológicas
      </div>
      {/* <Dropdown className="dropdown-images" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          {selectedImage ? (
            <img src={selectedImage.src} alt="Selected Image" />
          ) : (
            <i className="ri-image-line"></i>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {imagenes.map((image, index) => (
            <Dropdown.Item
              key={index}
              active={selectedImage === image}
              onClick={() => handleImageSelect(image)}
            >
              <img src={image.src} alt={`Image ${index + 1}`} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown> */}

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar">
            <img src={userAvatar} alt="{ user.name }" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl mb-3"><img src={userAvatar} alt="" /></div>
            <h5 className="mb-1 text-dark fw-semibold"> {user.name} </h5>
            {/* <p>Puesto2</p> */}
            <hr />
            <nav>
              <Link onClick={startLogout}><i className="ri-logout-box-r-line"></i> Cerrar Sesión</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
