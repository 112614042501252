import { configureStore } from '@reduxjs/toolkit';
import { uiSlice, authSlice, userSlice, permisoSlice, rolSlice, paisSlice, permisoXRolSlice, sistemaSlice } from './';


export const store = configureStore({
    reducer: {
        auth:     authSlice.reducer,
        ui:       uiSlice.reducer,
        user:     userSlice.reducer,
        permisos: permisoSlice.reducer,
        rol:      rolSlice.reducer,
        pais:     paisSlice.reducer,
        permisoXRol: permisoXRolSlice.reducer,
        sistemaRedux: sistemaSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})
