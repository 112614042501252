import { createSlice } from '@reduxjs/toolkit';

export const permisoSlice = createSlice({
    name: 'permiso',
    initialState: {
        permisos: [
            // tempEvent
        ],
        sistemas: [],
        roles: [],
        redireccion: [],
        getDataForm: [],
    },
    reducers: {
        setPermisos: (state, { payload } ) => {
            state.permisos = payload;
        },
        setDataSelectPermisos: (state, { payload  } ) => {
            state.sistemas = payload.sistemas.sistemas;
        },
        upSertPermiso: (state, {payload}) => {
            state.permisos = payload.respuesta.permisos;
        },
        setPermisoDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setPermisos,
    setDataSelectPermisos,
    upSertPermiso,
    setPermisoDataForm
} = permisoSlice.actions;