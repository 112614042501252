import { useDispatch, useSelector } from 'react-redux';
import services from '../services/servicesTeso';
import { onLogin, onLogout, setPais, setPaisDataForm, upSertPais, DataSelectPais } from '../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


export const usePrincipalStore = () => {

    const navigate = useNavigate();

    const envioIdSistema = (sis_id, link_id) => {

        localStorage.setItem('id_sistema', sis_id );
        localStorage.setItem('link_sistema', link_id);
        // navigate('/pages/paisempresa');
        navigate('/pages/pais_empresa');
    }
    
    return {
        envioIdSistema
    }

}