import { createSlice } from '@reduxjs/toolkit';

export const permisoXRolSlice = createSlice({
    name: 'permisoXRol',
    initialState: {
        permisosXRoles: [],
        sistemas: [],
        roles: [],
        empresas: [],
        permisos: [],
        getDataForm: [],

    },
    reducers: {
        setPermisosXRoles: (state, { payload } ) => {
            state.permisosXRoles = payload;
        },
        setPermisosXRolesAfterUpdate: (state, { payload } ) => {
            state.permisosXRoles = payload;
        },
        setDataSelectPermisosXRoles: (state, { payload  } ) => {
            state.roles = payload.dataSelect.roles;
            state.empresas = payload.dataSelect.empresas;
            state.permisos = payload.dataSelect.permisos;
        },
        upSertPermisoXRol: (state, {payload}) => {
            state.permisosXRoles = payload.respuesta.roles;
        },
        setPermisoXRolDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setPermisosXRoles,
    setPermisosXRolesAfterUpdate,
    setDataSelectPermisosXRoles,
    upSertPermisoXRol,
    setPermisoXRolDataForm
} = permisoXRolSlice.actions;