import React from "react";
import { Button, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2';

export default function SpinnerCarga(params) {

  const spinnerModal = () => {

    Swal.fire(
      {
        title: 'Cargando!',
        html: params.title,
        timer: 1,
        timerProgressBar: false,
        didOpen: () => {
            Swal.showLoading()
          },
      }
    )

  }

  return (

    //  Spinner de carga
    <div className="contenedorSpinnerLogin">
      <div className="SpinnerLogin">
        <Button variant="btn btn-outline-primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </Button>{' '}
        <Button variant="btn btn-outline-primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          { "   " + params.title }
        </Button>
      </div>
    </div>

      // <React.Fragment>

      //   { spinnerModal() }

      // </React.Fragment>

)

};
